import React, { useEffect, useRef } from 'react';
import PageLayout from '../../Container/PageLayout';
import './Skill.scss';

const Title = () => <h1>skills & experience</h1>;

function Skill() {
  const skillContainer = useRef();

  useEffect(() => {
    const skillList = skillContainer.current.children;
    let initialDelay = 0.3;
    for (const skill of skillList) {
      skill.style.transition = `width 1s`;
      skill.style.transitionDelay = `${initialDelay}s`;
      skill.style.opacity = '1';
      skill.style.width = skill.children[1].innerText;
      initialDelay = initialDelay + 0.1;
    }
  }, []);

  return (
    <div className="skill-container">
      <div className="content">
        <p>
          I have serious passion for web development and creating intuitive,
          dynamic user experiences.
        </p>
        <p>
          Before my professional career I was an SEO analyst, domain/hosting
          consultant & a freelance web developer. Professional experience
          includes building web products for some of the top product companies
          through my current employer.
        </p>
        <p>Interested in everything that involves building the web.</p>
      </div>
      <div className="skill-chart" ref={skillContainer}>
        <li className="chart__bar">
          <span className="chart__label header">HTML5</span>
          <span className="chart__label">82%</span>
        </li>
        <li className="chart__bar">
          <span className="chart__label header">CSS & SCSS</span>
          <span className="chart__label">80%</span>
        </li>
        <li className="chart__bar">
          <span className="chart__label header">React</span>
          <span className="chart__label">75%</span>
        </li>
        <li className="chart__bar">
          <span className="chart__label header">Javascript</span>
          <span className="chart__label">80%</span>
        </li>
        <li className="chart__bar">
          <span className="chart__label header">Angular</span>
          <span className="chart__label">75%</span>
        </li>
        <li className="chart__bar">
          <span className="chart__label header">ElectronJS</span>
          <span className="chart__label">65%</span>
        </li>
        <li className="chart__bar">
          <span className="chart__label header">NodeJS</span>
          <span className="chart__label">68%</span>
        </li>
        <li className="chart__bar">
          <span className="chart__label header">Webpack</span>
          <span className="chart__label">55%</span>
        </li>
        <li className="chart__bar">
          <span className="chart__label header">SEO</span>
          <span className="chart__label">51%</span>
        </li>
      </div>
    </div>
  );
}

export default PageLayout(Skill, Title);
