import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import PageLayout from '../../Container/PageLayout';
import './Contact.scss';

const Title = () => <h1>contact</h1>;

function Contact() {
  const [state, setState] = useState({});
  const [sendStatus, setSendStatus] = useState(false);
  const [errorState, setErrorState] = useState({
    name: false,
    email: false,
    message: false,
  });

  const handleChange = (param, e) => {
    setErrorState({ ...errorState, [param]: false });
    setState({ ...state, [param]: e.target.value });
  };

  const sendEmail = () => {
    if (state.email && state.message && state.name) {
      setSendStatus(true);
      emailjs
        .send('gmail', 'template_hKlmRSxT', state, 'user_TxKZ9mZQwPWFgcqDT3n1G')
        .then(
          (result) => {
            setSendStatus(false);
            setState({});
          },
          (error) => {
            console.log(error.text);
          }
        );
    } else {
      setErrorState({
        email: !!!state.email,
        name: !!!state.name,
        message: !!!state.message,
      });
    }
  };

  return (
    <div className="contact-wrapper">
      <div className="form-container">
        <p>Akhil,</p>
        <p>
          This is{' '}
          <input
            onChange={(e) => handleChange('name', e)}
            value={state.name || ''}
            className={`input-box ${errorState.name ? 'error' : ''}`}
            placeholder="your name"
          ></input>
          , and i'd like to chat.
        </p>
        <p>you can reach me at</p>
        <input
          onChange={(e) => handleChange('email', e)}
          value={state.email || ''}
          className={`input-box ${errorState.email ? 'error' : ''}`}
          placeholder="your email"
        ></input>
        <p>Here’s what I’m thinking:</p>
        <textarea
          onChange={(e) => handleChange('message', e)}
          value={state.message || ''}
          className={errorState.message ? 'error' : ''}
          placeholder="your message"
        ></textarea>
        <button onClick={sendEmail} className="submit-button">
          <ul className={sendStatus ? 'sending' : ''}>
            <li>
              <i className="fa fa-paper-plane"></i>
            </li>
            <li>
              <span className="send-text">SEND</span>
            </li>
            <li>
              <i className="fa fa-spinner"></i>
            </li>
          </ul>
        </button>
      </div>
      <div className="contact-details">
        <ul>
          <li>
            <i className="fa fa-map-marker"></i>
            <span>Bengaluru | India</span>
          </li>
          <li>
            <i className="fa fa-phone"></i>
            <span>+(91) 8050303807</span>
          </li>
          <li>
            <i className="fa fa-envelope-o"></i>
            <span>akhilbiju.t@gmail.com</span>
          </li>
        </ul>
      </div>
    </div>
  );
}
export default PageLayout(Contact, Title);
