import React from 'react';
import './Hamburger.scss';

export default function Hamburger(props) {
  return (
    <a
      className={`hamburger ${props.active ? 'active' : ''}`}
      href="/"
      onClick={props.clickHandler}
    >
      <span className={`line line1`}></span>
      <span className={`line line2`}></span>
      <span className={`line line3`}></span>
    </a>
  );
}
