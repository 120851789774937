import React from 'react';
import { NavLink } from 'react-router-dom';
import PageLayout from '../../Container/PageLayout';
import './NotFound.scss';

const Title = () => <h1>404</h1>;

function NotFound() {
  return (
    <div>
      <p>Maybe this page moved?</p>
      <p>Got deleted?</p>
      <p>Never existed in the first place</p>
      <p>
        Let's go{' '}
        <NavLink
          style={{ fontWeight: 600, color: 'white', textDecoration: 'none' }}
          to="/"
        >
          home
        </NavLink>{' '}
        and try from there.
      </p>
    </div>
  );
}

export default PageLayout(NotFound, Title);
