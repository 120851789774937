import React from 'react';

export default function MainBody(props) {
  const style = {
    flexGrow: '1',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-between',
    margin: '10px',
    border: 'solid 5px #909096',
    borderRadius: '255px 15px 225px 15px/15px 225px 15px 255px',
  };
  return (
    <>
      <div className="page">
        <div style={style}>{props.children}</div>
      </div>
    </>
  );
}
