import tbt from '../../../images/tbt.PNG';
import magicDecor from '../../../images/magicdecor.PNG';

const workData = [
  {
    title: 'MagicDecor',
    description: `A 3D room designer site built end-to-end with Javascript. User can use multiple room layouts
    and 3D room objects created on three.js. Features include zoom, resize, drag & drop, keyboard controls, 
    chat system etc.`,
    tags: [
      'angular',
      'three.js',
      'canvas',
      'nodejs',
      'JWT',
      'passport.js',
      'MongoDB',
      'Mongoose',
    ],
    preview: magicDecor,
    code: 'https://github.com/akhilbiju/magicDecorServer',
  },
  {
    title: 'TheBlogTip',
    description: `Serving over 20,000+ readers a month. A blog for newbie bloggers and latest SEO 
    strategies. I started this blog along with 2 other passionate people. I write articles about
    SEO & internet marketing techniques`,
    tags: ['wordpress', 'PHP', 'SEO', 'author', 'co-founder'],
    live: 'http://www.theblogtip.com',
    preview: tbt,
  },
];

export default workData;
