import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';

import './App.scss';
import NavBarLayout from './Components/Navbar/NavbarLayout';
import MainBody from './Components/Container/MainBody';
import Home from './Components/Pages/Home/Home';
import About from './Components/Pages/About';
import Skill from './Components/Pages/Skill/Skill';
import Contact from './Components/Pages/Contact/Contact';
import Work from './Components/Pages/Work/Work';
import NotFound from './Components/Pages/NotFound/NotFound';

function App() {
  const location = useLocation();
  return (
    <>
      <NavBarLayout />
      <MainBody>
        <AnimatePresence exitBeforeEnter>
          <Switch location={location} key={location.pathname}>
            <Route path="/" component={Home} exact />
            <Route path="/about" component={About} />
            <Route path="/skills" component={Skill} />
            <Route path="/work" component={Work} />
            <Route path="/contact" component={Contact} />
            <Route component={NotFound} />
          </Switch>
        </AnimatePresence>
      </MainBody>
    </>
  );
}

export default App;
