import React from 'react';
import { motion } from 'framer-motion';

const pageAnimation = {
  initial: {
    opacity: 0,
    x: '-100vw',
    scale: 0.8,
  },
  in: {
    opacity: 1,
    x: 0,
    scale: 1,
  },
  out: {
    opacity: 0,
    x: '100vw',
    scale: 1.3,
  },
};

const pageTransition = {
  type: 'tween',
  duration: 0.7,
  ease: 'anticipate',
};

export default function PageLayout(WrapComponent, Header) {
  return class extends React.Component {
    render() {
      return (
        <>
          <span className="tags"> &nbsp;&nbsp;&nbsp;&lt;&gt;</span>
          <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={pageAnimation}
            transition={pageTransition}
            className="content-zone"
          >
            <Header />
            <WrapComponent />
          </motion.div>
          <div className="footer">
            <span className="tags"> &nbsp;&nbsp;&nbsp;&lt;/&gt;</span>
            <p>
              Crafted with <span className="love-symbol">♥</span> on React
            </p>
          </div>
        </>
      );
    }
  };
}
