import React from 'react';
import { NavLink } from 'react-router-dom';

export default function Logo() {
  return (
    <NavLink className="logo" rel="index" to="/">
      <svg
        width="40"
        height="45"
        viewBox="0 0 44 59"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.4 45L8.6 33.608H27.544L30.744 45H35.608L23.448 0.711998H12.696L0.536 45H5.4ZM19.736 4.936L26.456 29.192H9.688L16.408 4.936H19.736Z"
          className="squiggle"
          stroke="white"
          strokeWidth="2"
        />
      </svg>
    </NavLink>
  );
}
