import React, { useEffect } from 'react';
import PageLayout from '../../Container/PageLayout';
import './Home.scss';
import { useState } from 'react';

const fullText = `Hi, I'm Akhil Biju`;

const Title = () => {
  const [state, setState] = useState('');

  useEffect(() => {
    const typeTimer = setTimeout(() => {
      if (state.length !== fullText.length) {
        setState(fullText.substring(0, state.length + 1));
      }
    }, 200);
    return () => clearTimeout(typeTimer);
  }, [state]);

  return <span className="header-home">{state}</span>;
};

function Home() {
  return <div>I build things for web.</div>;
}

export default PageLayout(Home, Title);
