import React from 'react';
import PageLayout from '../../Container/PageLayout';
import './Work.scss';
import workData from './WorkData';

const Title = () => <h1>my work</h1>;

function Work() {
  return (
    <>
      {workData.map((project, index) => {
        return (
          <div
            key={index}
            className={`work-container ${index % 2 === 0 && 'reverse'}`}
          >
            <div>
              <div className="browser">
                <div className="browser-header">
                  <i></i>
                  <i></i>
                  <i></i>
                  <i></i>
                </div>
                <div className="browser-body">
                  <img src={project.preview} alt="no preview available"></img>
                </div>
              </div>
              <div className="button-center">
                {project.live && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={project.live}
                    style={{ fontWeight: 600, textDecoration: 'none' }}
                  >
                    View live
                  </a>
                )}
                {project.code && (
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={project.code}
                    style={{ fontWeight: 600, textDecoration: 'none' }}
                  >
                    View code
                  </a>
                )}
              </div>
            </div>
            <div className="work-description">
              <h2>{project.title}</h2>
              <p>{project.description}</p>
              <div className="tech-tags">
                {project.tags.map((tag) => {
                  return <span key={tag}>#{tag}</span>;
                })}
              </div>
            </div>
          </div>
        );
      })}
    </>
  );
}

export default PageLayout(Work, Title);
