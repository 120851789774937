import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';

import './Navbar.scss';
import SocialLinks from './SocialLinks';
import Logo from './Logo';
import Hamburger from './Hamburger';

export default function NavBarLayout() {
  const [state, setState] = useState(false);

  const onNavClick = (e) => {
    e.preventDefault();
    setState((prevState) => !prevState);
  };

  return (
    <>
      <div className="nav_bar">
        <Logo />
        <nav className={state ? 'show' : ''}>
          <NavLink exact to="/">
            <i className="fa fa-home"></i>
          </NavLink>
          <NavLink to="/about">
            <i className="fa fa-user"></i>
          </NavLink>
          <NavLink to="/skills">
            <i className="fa fa-cog"></i>
          </NavLink>
          <NavLink to="/work">
            <i className="fa fa-eye"></i>
          </NavLink>
          <NavLink to="/contact">
            <i className="fa fa-envelope"></i>
          </NavLink>
        </nav>
        <SocialLinks />
        <Hamburger active={state} clickHandler={onNavClick} />
      </div>
    </>
  );
}
