import React from 'react';

export default function SocialLinks() {
  return (
    <ul>
      <li>
        <a
          href="https://www.linkedin.com/in/akhil-biju-02983314b"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-linkedin"></i>
        </a>
      </li>
      <li>
        <a
          href="https://www.facebook.com/akhilbiju1995"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa fa-facebook"></i>
        </a>
      </li>
    </ul>
  );
}
