import React from 'react';
import PageLayout from '../Container/PageLayout';

const Title = () => <h1>About</h1>;

function About() {
  return (
    <>
      <div>
        <p>
          Web development is my life, since 2013. Most of my coding experience
          were built upon the HTML, CSS & JavaScript, but I have played in the
          past with some other things like C, PHP, VBA.
        </p>
        <p>
          A couple years ago I started to play with React & Angular, and I'm
          still loving it. I'm open minded to new technologies, but Front end
          web development is the one that have grabbed my attention recently.
        </p>
        <p>
          I'm a hard worker, committed and self learner. Definitely, I'm not a{' '}
          <a
            style={{ fontWeight: 600, color: 'white', textDecoration: 'none' }}
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.linkedin.com/pulse/you-probably-ninja-jedi-superhero-rockstar-guru-genius-alan-walker"
          >
            guru jedi rockstar ninja
          </a>
          , but I always get my stuff done.
        </p>
      </div>
    </>
  );
}

export default PageLayout(About, Title);
